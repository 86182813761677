
// Vue reactivity
import { defineComponent, onMounted, ref, watchEffect } from 'vue';

// icons
import { close, person, call, mail } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonIcon,
        IonButtons, IonButton, IonInput, IonGrid, IonCol, IonRow, IonText,
        modalController, loadingController, toastController, alertController, } from '@ionic/vue';

// API services
import AuthService from '@/services/AuthService';
import firebase from 'firebase/compat/app';
import { auth, credentialSignIn, phoneSignIn, updateUserPhoneNumber, reauthUserWithCredential } from '@/auth';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import UserService from '@/services/UserService';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'SMSLoginModal',
  props: {
    editingUser: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonInput, IonText,
                IonGrid, IonCol, IonRow, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const store = useStore();

    const phone = ref("");
    const isInvalidPhone = ref(false);
    const isEditingPhone = ref(false);

    if (props.editingUser) { // editing post
      isEditingPhone.value = true;
      watchEffect(() => {
        phone.value = props.editingUser.value.phone;
      })
    }

    const window: any = {
      recaptchaVerifier: undefined,
      confirmationResult: undefined,
      verificationId: undefined,
    };
    onMounted(() => {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('grecaptcha-div', {
        'size': 'invisible',
        'callback': (res: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    })
    // methods or filters
    const { t } = useI18n();
    const { presentAlert, presentVerifyCodeInputAlert, iPhoneNativeApp, } = utils();

    const closeModal = async () => {
      await modalController.dismiss({});
    };
    const verifyCode = async (verificationCode: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      try {
        if (isEditingPhone.value == true) {
          const phoneCredential = firebase.auth.PhoneAuthProvider.credential(window.verificationId, verificationCode);
          await updateUserPhoneNumber(auth.currentUser, phoneCredential);
          await UserService.updateLoggedInUser({ ...props.editingUser.value, phone: phone.value }, props.editingUser.value);
          store.commit('updateUser', { phone: phone.value });
          reauthUserWithCredential(auth.currentUser, phoneCredential);
        } else {
          if (iPhoneNativeApp()) {
            const credential = firebase.auth.PhoneAuthProvider.credential(window.verificationId, verificationCode);
            await credentialSignIn(credential);
          } else {
            await window.confirmationResult.confirm(verificationCode);
          }
        }
        loading.dismiss();
        alertController.dismiss();
        closeModal();
      } catch (e) {
        loading.dismiss();
        if (e.code == "auth/invalid-verification-code") { // wrong verification code
          presentAlert(t('RegisterPage.invalidVerificationCode'))
        } else {
          presentAlert(e.message);
        }
      }
    }
    const presentCodeInputAlert = async (phone: any) => {
      await presentVerifyCodeInputAlert(phone, verifyCode);
    }
    const doSMSLogin = async (phone: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      const res = isEditingPhone.value || await AuthService.checkPhoneExists(phone);
      if (res === true) {
        try {
          // verify the phone
          const appVerifier = window.recaptchaVerifier;
          const verifyingPhone = `+852${phone}`;
          if (iPhoneNativeApp()) {
            const res: any = await FirebaseAuthentication.signInWithPhoneNumber({ phoneNumber: verifyingPhone });
            loading.dismiss();
            window.verificationId = res.verificationId;
            presentCodeInputAlert(phone);
          } else {
            if (isEditingPhone.value == true) {
              const provider = new firebase.auth.PhoneAuthProvider();
              window.verificationId = await provider.verifyPhoneNumber(verifyingPhone, appVerifier);
            } else {
              window.confirmationResult = await phoneSignIn(verifyingPhone, appVerifier);
            }
            presentCodeInputAlert(phone);
          }
        } catch (e) {
          loadingController.dismiss();
          if (e.code == "auth/invalid-phone-number") {
            presentAlert(t('RegisterPage.enterValidHKMobileNumber')); // invalid phone number
          } else {
            presentAlert(e.message);
          }
        }
      } else {
        loadingController.dismiss();
        isInvalidPhone.value = true;
      }
    }

    // 3. return variables & methods to be used in template HTML
    return {
      t,
      
      // icons
      close, mail, person, call,

      // variables
      phone, isInvalidPhone, isEditingPhone,

      // methods
      doSMSLogin, closeModal,
    }
  }
});
