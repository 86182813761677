import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da387a9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.scanningQRCode ? $setup.t('scanningQRCode') : $setup.t('profile')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.stopScan()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: $setup.close
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, $setup.scanningQRCode]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, {
            class: "ion-text-center",
            fixed: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card, { class: "no-border-card" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      ($setup.loading)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            size: "12",
                            class: "ion-text-center ion-margin-top"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_spinner)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!$setup.loading)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 1,
                            "size-xs": "12",
                            "size-md": "4"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                class: "user-image",
                                src: $setup.user.profilePic
                              }, null, 8, _hoisted_1),
                              _createVNode(_component_ion_button, {
                                class: "ion-margin-top",
                                color: "light",
                                size: "small",
                                onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.updateProfilePic()))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "start",
                                    icon: $setup.camera
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString($setup.t('UserProfilePage.changeProfilePhoto')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          (!$setup.loading)
                            ? (_openBlock(), _createBlock(_component_ion_card_header, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_title, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.user.firstName), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_subtitle, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.user.email), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_card_content, null, {
                            default: _withCtx(() => [
                              (!$setup.loading)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                    _createVNode(_component_ion_chip, {
                                      color: "tertiary",
                                      onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.openEditPhoneModal())),
                                      outline: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, { icon: $setup.phonePortraitOutline }, null, 8, ["icon"]),
                                        _createElementVNode("span", null, _toDisplayString($setup.user.phone) + " ", 1),
                                        _createVNode(_component_ion_icon, { icon: $setup.createOutline }, null, 8, ["icon"])
                                      ]),
                                      _: 1
                                    })
                                  ]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_ion_button, {
                                class: "ion-margin-bottom",
                                expand: "block",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.openEditUserProfileModal()))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "start",
                                    icon: $setup.createOutline
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString($setup.t('UserProfilePage.editPersonalInfo')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                expand: "block",
                                onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.presentChangePasswordPrompt()))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "start",
                                    icon: $setup.keyOutline
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString($setup.t('UserProfilePage.changePassword')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_list, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        slot: "start",
                                        icon: $setup.globe
                                      }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_select, {
                                        label: $setup.t('UserProfilePage.language'),
                                        interface: "popover",
                                        modelValue: $setup.locale,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.locale) = $event))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_select_option, { value: "zh" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("中文")
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_select_option, { value: "en" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("English")
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["label", "modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                fill: "clear",
                                size: "small",
                                "router-link": "/terms-and-conditions"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('UserProfilePage.termsAndConditions')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                fill: "clear",
                                size: "small",
                                "router-link": "/feedback"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('UserProfilePage.feedback')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_row, { class: "ion-margin" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { size: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            color: "medium",
                            size: "small",
                            class: "logout-btn",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.doLogout()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "end",
                                icon: $setup.logOutOutline
                              }, null, 8, ["icon"]),
                              _createTextVNode(" " + _toDisplayString($setup.t('UserProfilePage.logout')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        size: "12",
                        class: "ion-margin-top"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_note, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Ver. " + _toDisplayString($setup.versionCode), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      ($setup.userLoggedIn)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            style: {"margin":"30px auto"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                fill: "clear",
                                color: "danger",
                                size: "small",
                                onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.deleteAccount()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($setup.t('deleteAccount')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512), [
        [_vShow, !$setup.scanningQRCode]
      ])
    ]),
    _: 1
  }))
}