
// Vue
import { computed, watch } from 'vue';

// icons
import { logOutOutline, createOutline, keyOutline, camera, globe, moon, add, close, phonePortraitOutline, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonCol, IonSpinner, IonList, IonListHeader, IonItem, IonLabel,
        IonIcon, IonButtons, IonBackButton, IonButton, IonNote, IonImg,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
        IonToggle, IonSelect, IonSelectOption, IonBadge, IonChip, IonFabButton,
        alertController, modalController, loadingController, } from '@ionic/vue';
import UserProfileFormModal from '@/components/modals/UserProfileFormModal.vue';
import SMSLoginModal from '@/components/modals/SMSLoginModal.vue';

// API services
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';

// utils or methods
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useQRCodeScanner } from '@/composables/useQRCodeScanner';

import config from '@/config';
import { utils } from '@/composables/utils';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

export default {
  name: 'UserProfile',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonRow, IonCol, IonSpinner, IonList, IonListHeader, IonItem, IonLabel,
                IonIcon, IonButtons, IonBackButton, IonButton, IonNote, IonImg,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
                IonToggle, IonSelect, IonSelectOption, IonBadge, IonChip, IonFabButton, },
  setup() {
    const store = useStore();
    
    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingUser);
    const user = computed(() => store.state.user);
    const userLocations = computed(() => store.state.userLocations);
    const userLoggedIn = computed(() => store.state.loggedIn);
    
    // methods or filters
    const { t, locale } = useI18n();
    const { scanningQRCode, startScanLocationQRCode, stopScan, openUserLocationModal, } = useQRCodeScanner();
    const { presentToast, sleep, presentPrompt, } = utils();

    const doLogout = async () => {
      const alert = await alertController.create({
        header: t('UserProfilePage.logout'),
        message: t('UserProfilePage.confirmLogout'),
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: t('UserProfilePage.logout'),
            handler: () => {
              AuthService.doLogout();
            }
          }
        ]
      });
      return alert.present();
    }
    const presentChangePasswordPrompt = async () => {
      const alert = await alertController.create({
        header: t('UserProfilePage.changePassword'),
        inputs: [
          {
            name: 'oldPassword',
            type: 'password',
            placeholder: t('UserProfilePage.oldPassword')
          },
          {
            name: 'newPassword',
            type: 'password',
            placeholder: t('UserProfilePage.newPassword')
          },
          {
            name: 'newPasswordConfirm',
            type: 'password',
            placeholder: t('UserProfilePage.newPasswordConfirm')
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: t('confirm'),
            handler: (value) => {
              if (value.oldPassword && value.newPassword && value.newPasswordConfirm) {
                if (value.newPassword != value.newPasswordConfirm) {
                  presentToast(t('UserProfilePage.inconsistentNewPasswordMsg'), 5000, 'top');
                } else {
                  AuthService.updatePassword(value.oldPassword, value.newPassword).then(res => {
                    if (res == 'wrong-password') {
                      presentToast(t('UserProfilePage.wrongOldPassword'), 5000, 'top');
                    } else {
                      presentToast(t('UserProfilePage.successUpdatePassword'), 3000, 'top');
                      alert.dismiss();
                    }
                  });
                }
              } else {
                presentToast(t('UserProfilePage.enterAllFields'), 5000, 'top');
              }
              return false;
            }
          }
        ]
      });
      await alert.present();
    }

    // update user phone number (need SMS verification)
    const openEditPhoneModal = async () => {
      const modal = await modalController.create({
        component: SMSLoginModal,
        componentProps: { editingUser: user },
        cssClass: 'small-modal',
      });
      return modal.present();
    }

    // update user information like first name
    const openEditUserProfileModal = async () => {
      const modal = await modalController.create({
        component: UserProfileFormModal,
        componentProps: { editingUser: user },
      });
      return modal.present();
    }

    // Update user profile picture (Capacitor Camera)
    const updateProfilePic = async () => {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        quality: 60,
        width: 1500,
      });
      const loading = await loadingController.create({});
      await loading.present();
      const fileName = `${moment().format('YYYYMMDDHHmmss')}-${user.value.username}.${cameraPhoto.format}`;
      UserService.updateLoggedInUserProfilePic(cameraPhoto.dataUrl, fileName, user.value.profilePic);
      store.commit('updateUser', { profilePic: cameraPhoto.dataUrl });
      loading.dismiss();
      presentToast(t("UserProfilePage.successChangeProfilePhoto"), 3000, 'top');
    }

    const toggleDarkTheme = async (enableDarkTheme: boolean) => {
      document.body.classList.toggle('dark', enableDarkTheme);
      if (user.value.id) { // only for logged in users
        const currDark: any = user.value.darkTheme;
        if ((currDark == true && !enableDarkTheme) || ((currDark == false || currDark == '') && enableDarkTheme)) {
          const loading = await loadingController.create({});
          await loading.present();
          UserService.updateUserDarkThemeSetting(enableDarkTheme);
          document.body.classList.toggle('dark', enableDarkTheme);
          store.commit('updateUser', { darkTheme: enableDarkTheme });
          await sleep(1);
          loading.dismiss();
        }
      }
    }

    const deleteAccount = async () => {
      presentPrompt(t('deleteAccount'), t('confirmDeleteAccountMsg'), async () => {
        const loading = await loadingController.create({});
        await loading.present();
        await UserService.deleteLoggedInUser();
        loading.dismiss();

        AuthService.doLogout();
        presentToast(t('accountDeleted'), 3000);
      });
    }

    watch(locale, (currLocale, prevLocale) => {
      if (user.value.id) { // only for logged in users
        UserService.updateUserAppLanguage(currLocale);
      }
    });
    
    // 3. return variables & methods to be used in template HTML
    return {
      t, locale,

      // icons
      logOutOutline, createOutline, keyOutline, camera, globe, moon, add, close, phonePortraitOutline,

      // variables
      loading, user, userLocations, userLoggedIn,
      versionCode: config.versionCode,
      scanningQRCode,

      // methods
      toggleDarkTheme, doLogout, presentChangePasswordPrompt,
      openEditPhoneModal, openEditUserProfileModal, updateProfilePic,
      openUserLocationModal, startScanLocationQRCode, stopScan,
      deleteAccount,
    }
  },
}
